import React from 'react'
import Container from './Component/Layout/Container'
import Menu from './Component/Page/Menu'

const Home = (props) => {
    return (
        <Container homeDetail={props.homeDetail}>
            <Menu homeDetail={props.homeDetail} handleLang={props.handleLang} />
        </Container>
    )
}

export default Home
