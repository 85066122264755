import React, { useEffect, useRef, useState } from 'react';
import { io } from 'socket.io-client';
import { speakMessage, translate } from '../../utils';
import { SOCKET_URL } from '../../constant';
import Container from '../Layout/Container';

const Screen = ({homeDetail}) => {

    const [message, setMessage] = useState('');
    const [translatedLang, setTranslatedLang] = useState('ar');
    const [enableVoice, setEnableVoice] = useState(false);

    const transLangRef = useRef('ar');
    const enableLangRef = useRef(null);


    const EnableVoice = (value) => {
        enableLangRef.current.value = value
        setEnableVoice(!enableVoice);
    }

    const handleLanguageChange = (e) => {
        setTranslatedLang(e.target.value);
        transLangRef.current.value = e.target.value
        if (message !== '') {

        }

    };

    useEffect(() => {
        console.log('enableVoice', enableVoice);
        const socket = io(SOCKET_URL, {
            path: '/socket.io',
            transports: ['websocket'],
            //   secure: false,
        });
        console.log('socket connected');

        socket.on("new-word-stream", (text) => {
            console.log('new event');
            let receivedData = text.split("####");
            translate(receivedData[0], receivedData[1].split('-')[0], transLangRef.current.value).then(res => {
                setMessage(res);
                if (enableLangRef && enableLangRef.current && enableLangRef.current.value) {
                    navigator.mediaDevices.getUserMedia({ audio: true })
                        .then(function (stream) {
                            console.log('You let me use your mic!')
                            speakMessage(res, transLangRef.current.value)
                        })
                        .catch(function (err) {
                            console.log('No mic for you!')
                        });
                } else {
                    console.log('voice disable');
                }
            })

        });

        // Socket event listeners for receiving audio chunks
        socket.on('new-audio-stream', (data) => {
            console.log('event received ', enableVoice);
            if (enableVoice && transLangRef.current.value === 'ar') {
                console.log('play audio');

                // playAudio([data]);
            }
        });

        return () => {
            socket.disconnect(); // Close the socket connection when the component unmounts
            console.log('socket disconnected')
        };
    }, [enableVoice]);

    // Function to play received audio stream
    const playAudio = async (audioData) => {
        if (audioData.length > 0 && enableLangRef && enableLangRef.current && enableLangRef.current.value) {
            console.log('playing audio')
            try {
                await new Promise((resolve, reject) => {
                    const audioBlob = new Blob(audioData, { type: 'audio/wav' });
                    const audioURL = URL.createObjectURL(audioBlob);
                    const audio = new Audio(audioURL);
                    audio.play().then(resolve).catch(reject => {
                        console.log(reject)
                    });
                })
            } catch (error) {
                console.error('Permission audio denied:', error);
            }
        }
    };

    return (
        // <Container homeDetail={homeDetail}>
            <div className='black-container'>
                {/* <h1 className='home-paragraph arabic-text'
                style={{ backgroundColor: homeDetail?.color, border: homeDetail?.color }}>
                {homeDetail?.student_instruction}</h1> */}
                <div
                    className="text-message-student"
                >
                    {message}
                </div>

                {/* <span >الرجاء تحديد لغتك </span> */}
                <select
                    id="languageDropdown"
                    className="select select-small"
                    onChange={handleLanguageChange}
                    value={translatedLang}
                    ref={transLangRef}
                >
                    <option value="ar">Arabic</option>
                    <option value="en">English</option>
                    <option value="zh">Chinese</option>
                    <option value="fr">Francais</option>
                    <option value="hi">Hindi</option>
                </select>
                {/* <button
                    className={!enableVoice ? "button-toggle" : "button"}
                    onClick={() => EnableVoice()}
                    ref={enableLangRef}
                >
                    {enableVoice ? 'الغاء تفعيل الصوت' : 'تفعيل الصوت'}
                </button> */}
            </div>

        // </Container >
    );
};

export default Screen;
