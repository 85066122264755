import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Dictaphone from "./Component/Page/Dictaphone";
import Screen from "./Component/Page/Screen";
import Home from "./Home";
import Container from "./Component/Layout/Container";

function App() {

  const [homeDetail, setHomeDetail] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
      handleLang('en');
  }, []);

  const handleLang = (lang) => {
      fetch(`${apiUrl}/getActiveDetail?lang=${lang}`)
          .then((response) => { return response.json() })
          .then((data) => {
              if (data.status) {
                setHomeDetail(data.data);
              }
          })
          .catch((error) => {
              console.log('----------error---------', error)
          })
  }

  return (
    <div className="App">
        <Router>
          <Routes>
            <Route path="/student" element={<Screen homeDetail={homeDetail}/>} />
            <Route path="/teacher" element={<Dictaphone homeDetail={homeDetail}/>} />
            <Route path="/" element={<Home homeDetail={homeDetail} handleLang={handleLang}/> } />
          </Routes>
        </Router>
    </div>
  );
}

export default App;
