import axios from "axios";

let speaking = false;

export const speakMessage = (message, lang) => {
    if ('speechSynthesis' in window) {
        const speech = new SpeechSynthesisUtterance();
        console.log('speech: ', speech)
        
        // Set volume (0 to 1)
        speech.volume = 1; // Adjust as needed
        
        // Set pitch (0 to 2)
        speech.pitch = 1; // Adjust as needed
        
        
        // Set language to Arabic
        // speech.lang = speakLang;
        // speech.lang = 'zh-CN';
        speech.lang = lang;
        
        // Set the text in Arabic along with phonetic pronunciation if needed
        speech.text = message;
        
        // Set rate (speed)
        speech.rate = 0.8; // Adjust as needed
        console.log('speech 2: ', speech)
        if (!speaking) {
            speaking = true;
            window.speechSynthesis.speak(speech);
            console.log('speaking speech');
            speech.onend = () => {
                // When speech ends, reset speaking to false
                speaking = false;
            };
            speech.onerror = (event) => {
                // Handle speech synthesis errors here
                console.error('Speech synthesis error:', event.error);
                speaking = false; // Reset speaking flag
            };

        }
    } else {
        console.error('Speech Synthesis API not supported in this browser');
    }
};

export const translate = async (text, lang, translatedLang) => {
    const options = {
        method: 'POST',
        url: 'https://microsoft-translator-text.p.rapidapi.com/translate',
        params: {
            'to[0]': translatedLang,
            'api-version': '3.0',
            from: lang,
            profanityAction: 'NoAction',
            textType: 'plain'
        },
        headers: {
            'content-type': 'application/json',
            // 'X-RapidAPI-Key': '1811d2a188msh0705d23e6c73db3p1785c3jsnec71b517ff21',
            'X-RapidAPI-Key': 'b78065b757msh463d7c344931d6dp161a47jsn6f07b19a878a',
            'X-RapidAPI-Host': 'microsoft-translator-text.p.rapidapi.com'
        },
        data: [
            {
                Text: text
            }
        ]
    };

    try {
        const response = await axios.request(options);
        return response.data[0].translations[0].text;
    } catch (error) {
        console.error(error);
    }
}

export const teacherPassword = '1122';
