import React from 'react'
// import { useLocation } from 'react-router-dom';

const Logo = ({logo}) => {
// const location = useLocation();
  const img = `${process.env.REACT_APP_API_URL}/${logo}`
  return (
    <div className='logo-container'>
    <img  className={'location.pathname' == '/' ? 'logo' : 'logo logo-small'} src={img} alt="" />
    </div>
  )
}

export default Logo
