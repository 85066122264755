import React from 'react'
import Logo from '../Logo'
// import { useLocation } from 'react-router-dom';

const Header = ({homeDetail}) => {
  // const location = useLocation();
  return (
    <div className='header'>
        <h1 className={'location.pathname' == '/' ? 'title-header' : 'title-header small-title'}>{homeDetail?.develop_by}</h1>
        <Logo logo={homeDetail?.logo}/>
    </div>
  )
}

export default Header
