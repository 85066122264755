import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { ReactComponent as VisitorIcon } from '../assets/visitor-icon.svg';
import { ReactComponent as DeafIcon } from '../assets/deaf-icon.svg';

const Menu = ({homeDetail, handleLang}) => {

    return (
        <div className='menu-container'>
            <h4 className='home-paragraph arabic-text'
            style={{ backgroundColor: homeDetail?.color, border: homeDetail?.color }}>
            {homeDetail?.instruction}
            </h4>
            <div className="card-container">
                <Link to="/teacher">
                    <div className='card'>
{/*                         <img className='card-image' src="/images/visitor-icon.svg" alt=""/> */}
                        <VisitorIcon className="card-image" style={{ fill: homeDetail?.color }} />
                        <h3 className='card-title'>{homeDetail?.teacher}</h3>
                    </div>
                </Link>
                <Link to="/student">
                    <div className='card'>
{/*                         <img className='card-image' src="/images/deaf-icon.svg" alt="" /> */}
                        <DeafIcon className="card-image" style={{ fill: homeDetail?.color }} />
                        <h3 className='card-title'>{homeDetail?.student}</h3>
                    </div>
                </Link>
            </div>
            <div className="language-container">
                <h3 className='language-text'>Language</h3>
                <button className='language-btn active' type="button"
                    onClick={() => handleLang('en')}>English</button>
                <button className='language-btn' type="button"
                    onClick={() => handleLang('ar')}>Arabic</button>
            </div>
        </div>
    )
}

export default Menu
