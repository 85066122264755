import React from 'react'
import Header from './Header'
import Body from '../Body'
import Footer from './Footer'

const Container = (props) => {
  return (
    <div className='container'>
      <Header homeDetail={props.homeDetail}/>
      <Body>
        {props.children}
      </Body>
      {/* <Footer/> */}
    </div>
  )
}
export default Container
