import React, { useEffect, useState, useCallback, useRef } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { io } from 'socket.io-client';
import { SOCKET_URL } from '../../constant';
import { teacherPassword } from '../../utils';
import Container from '../Layout/Container';

const Dictaphone = ({ homeDetail }) => {
  const [message, setMessage] = useState('');
  const [lang, setLang] = useState('ar');
  const [listening, setListening] = useState(false); // New state to handle continuous listening
  const [isRecording, setIsRecording] = useState(false);
  const [socket, setSocket] = useState(null);
  const [audioURL, setAudioURL] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(
    sessionStorage.getItem('isAuthenticated') === 'true' || true
  );
  const [password, setPassword] = useState('');
  const mediaRecorder = useRef(null);
  const chunks = useRef([]);

  const commands = [
    {
      command: 'reset',
      callback: () => resetTranscript()
    },
    {
      command: 'shut up',
      callback: () => setMessage("I wasn't talking.")
    },
    {
      command: 'Hello',
      callback: () => setMessage('Hi there!')
    },
  ];

  useEffect(() => {
    if (!socket) {
      setSocket(io(SOCKET_URL));
      console.log('set socket called!');
    }
  }, []);

  const {
    transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition({ commands });

  useEffect(() => {

    if (finalTranscript !== '') {
      console.log('Got final result:', finalTranscript);
      if (socket) {
        console.log('emit:', transcript);
        resetTranscript();
        if (isRecording) {
          stopRecording()
        }

      }
    }


    if (interimTranscript !== '') {
      console.log('Got interim result:', interimTranscript);
      socket.emit('word-stream', interimTranscript + '####' + lang);
    }
    else if (lang == 'ar' && listening) {
      // console.log('start recording');
      // startRecording()
    }

  }, [finalTranscript, interimTranscript, lang, listening, transcript]);

  // const startRecording = () => {
  //   // console.log('recording voice');
  //   navigator.mediaDevices.getUserMedia({ audio: true })
  //     .then((stream) => {
  //       mediaRecorder.current = new MediaRecorder(stream);
  //       mediaRecorder.current.ondataavailable = (e) => {
  //         // console.log('recording data available');
  //         if (e.data.size > 0) {
  //           chunks.current.push(e.data);
  //           socket.emit('audioStream', e.data); // Emit chunks to socket
  //         }
  //       };
  //       mediaRecorder.current.onstop = () => {
  //         const blob = new Blob(chunks.current, { type: 'audio/wav' });
  //         setAudioURL(URL.createObjectURL(blob));
  //       };
  //       mediaRecorder.current.start();
  //       setIsRecording(true);
  //     })
  //     .catch((err) => {
  //       console.error('Error accessing the microphone:', err);
  //     });
  // };

  const stopRecording = () => {
    if (mediaRecorder.current && isRecording) {
      mediaRecorder.current.stop();
      setIsRecording(false);
    }
  };

  useEffect(() => {
    return () => {
      if (socket) {
        socket.disconnect();
        console.log('socket disconnected')
      }
    };
  }, [socket]);

  const handleLanguageChange = useCallback(
    (e) => {
      setLang(e.target.value);
      stopRecording();
      if (e.target.value === 'ar') {
        // startRecording();
      }
      resetTranscript();
    },
    [resetTranscript]
  );

  const listenContinuously = useCallback(() => {
    setListening(true);
    console.log('setListening(true)');
    SpeechRecognition.startListening({
      continuous: true,
      interimResults: true,
      language: lang,
    });
  }, [lang]);

  const stopListening = useCallback(() => {
    setListening(false);
    SpeechRecognition.stopListening();
    stopRecording();
  }, []);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = () => {
    if (password === teacherPassword) {
      setIsAuthenticated(true);
      sessionStorage.setItem('isAuthenticated', 'true');
    } else {
      alert('Incorrect password. Please try again.');
    }
  };

  return (
    <Container homeDetail={homeDetail}>
      {isAuthenticated ? (
        <div className='flex-column'>
          <h2 className='home-paragraph arabic-text'
            style={{ backgroundColor: homeDetail?.color, border: homeDetail?.color }}>
            {homeDetail?.teacher_instruction}</h2>
          {/* <span> الحالة : {listening ? 'فعال' : 'غير فعال'}</span> */}
          <div style={{display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', gap: '4px'}}>

            <span> : الحالة 
            </span>
            {listening ?
              <div className="success-button" >
                تشغيل
              </div>
              :
              <div className="danger-button">
                إيقاف
              </div>
            }
          </div>
          <span >إختر لغتك: </span>
          <select className="select" id="languageDropdown" onChange={handleLanguageChange} value={lang}>
            <option value="ar-KW">عربي (Kuwait)</option>
            <option value="ar-XA">عربي (Multi-Region)</option>
            <option value="ar-JO">عربي (Jordan)</option>
            <option value="ar-AE">عربي (United Arab Emirates)</option>
            <option value="ar-BH">عربي (Bahrain)</option>
            <option value="ar-DZ">عربي (Algeria)</option>
            <option value="ar-SA">عربي (Saudi Arabia)</option>
            <option value="ar-IQ">عربي (Iraq)</option>
            <option value="ar-MA">عربي (Morocco)</option>
            <option value="ar-TN">عربي (Tunisia)</option>
            <option value="ar-OM">عربي (Oman)</option>
            <option value="ar-PS">عربي (Palestine)</option>
            <option value="ar-QA">عربي (Qatar)</option>
            <option value="ar-LB">عربي (Lebanon)</option>
            <option value="ar-EG">عربي (Egypt)</option>
            <option value="en-US">English</option>
            <option value="zh-CN">Chinese</option>
            <option value="fr-FR">Francais</option>
            <option value="hi-IN">Hindi</option>
          </select>
          <div className='button-container'>
            <button className="warning-button" type="button" onClick={resetTranscript}>
              البدء من جديد
            </button>
            <button className="success-button" type="button" onClick={listenContinuously}>
              تشغيل
            </button>
            <button className="danger-button" type="button" onClick={stopListening}>
              إيقاف
            </button>
          </div>
        </div>
      ) : (
        <div className='flex-column center'>
          <span>Enter Password </span>
          <input
            className='input'
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={handlePasswordChange}
          />
          <button className='button' onClick={handleLogin}>Login</button>
        </div>
      )}
    </Container>
  );
};

export default Dictaphone;
